/* General Styles */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-color: #fff;
}

body {
  margin: 0;
  font-family: 'Alice', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  border: 5px solid #cda19f;
  box-sizing: border-box;
}

.MuiTypography-h1, .MuiTypography-h2, .MuiTypography-h3, .MuiTypography-h4, .MuiTypography-h5, .MuiTypography-h6 {
  font-family: 'Sacramento', serif !important;
}

.MuiTypography-p, li, a, p, button {
  font-family: 'Alice', sans-serif !important;
}

.MuiTypography-h1 {
  font-size: 4rem !important;
}

.MuiTypography-h2 {
  font-size: 2.5rem !important;
}

.MuiTypography-h4 {
  margin-bottom: 10px;
}

.MuiTypography-p {
  font-size: 1.25rem;
}

/* Navbar Styles */

.navbar {
  background-color: #fff  !important;
  margin-bottom: 40px;
}

.navbar-logo {
  height: 150px;
  padding: 5px;
  border: none;
}

/* Home Page Styles */

.home {
  text-align: center;
}

.hero .MuiContainer-root {
  padding-top: 40px;
  padding-bottom: 50px;
}

.hero .MuiTypography-p {
  line-height: 2rem;
}

.home-secondary {
  text-align: center;
  padding-top: 40px;
}

.home-secondary a {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  background-color: #db4f56;
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
}

/* About Page Styles */

.about {
  text-align: center;
}

.about .MuiTypography-h1 {
  margin-bottom: 20px;
}

.about .MuiTypography-p {
  line-height: 2rem;
}

/* Contact Page Styles */

.contact {
  text-align: center;
}

.contact .MuiTypography-h1 {
  margin-bottom: 20px;
}

.contact .MuiTypography-p {
  margin-bottom: 20px;
}

.contact .MuiSvgIcon-root {
  color: #db4f56;
}

/* Cakes Page Styles */

.cakes {
  text-align: center;
}

.cakes .MuiTypography-h2 {
  margin-bottom: 10px;
}

/* Menu Page Styles */

.menu {
  text-align: center;
}

.menu .MuiTypography-h2 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
}

.menu .MuiTypography-p {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

/* Footer Styles */

.footer {
  background-color: #fdf0f1;
  padding: 30px 0px;
  margin-top: 30px;
  text-align: center;
}

.ytown-mark {
  margin: 20px 0;
}
